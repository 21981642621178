import React from "react"
import LoginModal from "../components/loginModal"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box } from "theme-ui"
import { useAuth } from "gatsby-theme-firebase"
import { borderRadius } from "polished"

export default ({ location }) => {
  const { isLoggedIn } = useAuth()
  const styles = {
    content: {
      bg: "contentBg",
      padding: "1rem",
      borderRadius: `lg`,
      boxShadow: `1px 1px 5px 0 rgba(1,1,1,.05)`,
    },
  }
  return (
    <Layout>
      <SEO title="Private Millionaire Millennial Discord Channel" />
      <Box sx={styles.content}>
        <h1>Join our Private Discord Channel!</h1>

        {isLoggedIn && (
          <div>
            Click this link and join the Millionaire Millennial channel: <br />
            <br />
            <center>
              <b>
                <a
                  href="https://discord.gg/mbcyMUuNSx"
                  target="_blank"
                  rel="noopener"
                >
                  https://discord.gg/mbcyMUuNSx
                </a>
              </b>
            </center>
            <br />
            <br />
            You can get the{" "}
            <a
              href="https://discord.com/download"
              target="_blank"
              rel="noopener"
            >
              app for your phone or download the client
            </a>{" "}
            to your PC as well as access from the web!
          </div>
        )}
        {!isLoggedIn && (
          <div>
            It looks like you're either not logged in or don't have an
            Millionaire Millennial account. Fix that now to access the Discord
            channel!
            <div style={{ paddingTop: 20, margin: "auto", maxWidth: 300 }}>
              <LoginModal buttonText="Login / Signup" course={{}} />
            </div>
          </div>
        )}
      </Box>
    </Layout>
  )
}
